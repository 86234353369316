import React from "react";
import VoyagerHero from "../components/Voyager/VoyagerHero";
import HomeTabs from "../components/HomeTabs";
import { useLoaderData } from "react-router-dom";
import { getModelsAction } from "../actions/getModels";

// The index page

// Called on page load and provides the slug to the fetch action which returns to the component
export async function loader() {
  
  return {id:'4e524d2c-a94f-4bbf-9349-03f22996c5e4'}

  const { data:models } = await getModelsAction({isHeroScene: true}).catch(e => null)
  
  const random = Math.floor(Math.random() * models.length);
  
  const model = models[random]

  return (model ? model : null)
}

export default function Home(props) {
  const model = useLoaderData()

  return (
    <div className="App">
      <VoyagerHero packageID={model?.id} />
      <div className="container">
        <HomeTabs  variant="scrollable" scrollButtons allowScrollButtonsMobile />
      </div>
    </div>
  );
}
