import { AppBar, Box, Toolbar, Typography } from "@mui/material";
import React from "react";

export default function Footer(props) {
  return (
    <Box>
      <AppBar position="relative" sx={{ top: 'auto', bottom: 0, overflow:"auto" }}>
        <Toolbar>
          <Box 
            sx={{
              marginX:{
                xs:0,
                md:5,
              },
              marginY:5
            }}
            style={{
              letterSpacing:"0.05em"
            }}>
              <Typography variant="h4">
                Contact
              </Typography>
              24412 IA-13<br/>
              Elkader, IA 52043<br/><br/>

              <a href="mailto:information@earthmovinglegacy.com?subject=Earthmoving Legacy Center Website Inquiry!" target="_self" style={{ color:"white" }}>
                information@earthmovinglegacy.com
              </a>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
