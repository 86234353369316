import React from "react";
import { CircularProgress, Grid } from "@mui/material";
import InformationItem from "./InformationItem";
import InformationList from "./InformationList";
// import * as data from "../data.json";

export default function InformationBlock({freetext, descriptiveNonRepeating}) {
  // If we haven't recieved information yet, display a loader
  if (!freetext && !descriptiveNonRepeating) return <CircularProgress size={100} sx={{margin:"auto"}} />
  // Otherwise map the information to lists
  return (
    <>
      <InformationList list={freetext.notes} />
      <InformationList list={freetext.identifier} />
      <InformationList list={freetext.name} />
      <InformationList list={freetext.physicalDescription} />
      <InformationList list={freetext.place} />
      <InformationList list={freetext.dataSource} />
      <InformationList list={freetext.date} />
      <InformationList list={freetext.objectRights} />
    </>
  );
}
