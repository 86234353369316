import React, { useState } from "react";
import SearchIcon from '@mui/icons-material/Search';
import { styled, alpha, InputBase, IconButton } from "@mui/material";

// Styling for different elements
const Search = styled('div')(({ theme }) => ({
	position: 'relative',
	borderRadius: theme.shape.borderRadius,
	backgroundColor: alpha(theme.palette.common.white, 0.15),
	'&:hover': {
		backgroundColor: alpha(theme.palette.common.white, 0.25),
	},
}));

const SearchOutlined = styled('div')(({ theme }) => ({
	position: 'relative',
	borderRadius: theme.shape.borderRadius,
	border: '2px solid '+alpha(theme.palette.primary.main, 1),
	'&:hover': {
		backgroundColor: alpha(theme.palette.primary.main, 0.2),
	},
}));
  
const SearchIconWrapper = styled('div')(({ theme }) => ({
	padding: theme.spacing(0, 2),
	height: '100%',
	position: 'absolute',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
}));
  
const InteractiveSearchIconWrapper = styled(SearchIconWrapper)(({ theme }) => ({
	right: 0,
	padding: theme.spacing(0, 1),
	zIndex: 9,
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
	overflow: 'hidden',
	color: 'inherit',
	display: 'block',
	'& .Mui-disabled': {
		filter: 'grayscale(1)',
		backgroundColor: alpha('#aaa',0.5),
	},
	'& .MuiInputBase-input': {
		padding: theme.spacing(1, 1, 1, 1),
		// vertical padding + font size from searchIcon
		paddingLeft: `calc(1em + ${theme.spacing(4)})`,
		transition: theme.transitions.create('width'),
		maxWidth: 'fir-content',
		maxWidth: '-webkit-fill-available',
	},
}));

const InteractiveStyledInputBase = styled(StyledInputBase)(({ theme }) => ({
	'& .MuiInputBase-input': {
		padding: theme.spacing(1, 1, 1, 1),
		// vertical padding + font size from searchIcon
		paddingRight: `calc(1em + ${theme.spacing(4)})`,
	},
}));

// Interiors for search bar variants
const SearchInnards = ({interactiveIcon, onSubmit = () => {}, ...props}) => {

	// If a key event is the enter key
	const catchReturn = (ev) => {
		if (ev.key === 'Enter') {
			// Submit the search value and prevent any other events
			const submit = onSubmit
			submit(ev.target.value)
			ev.preventDefault();
		}
	}

	// If the interactive icon prop is true make it submit clickable and use different styling 
	if (interactiveIcon) return (<>
		<InteractiveSearchIconWrapper>
			<IconButton aria-label="search" onClick={onSubmit}>
				<SearchIcon />
			</IconButton>
		</InteractiveSearchIconWrapper>
		<InteractiveStyledInputBase onKeyDown={catchReturn} {...props} inputProps={{ 'aria-label': 'search' }}/>
	</>) 
	// Otherwise just do regular
	return (<>
		<SearchIconWrapper>
			<SearchIcon />
		</SearchIconWrapper>
		<StyledInputBase onKeyDown={catchReturn} {...props} inputProps={{ 'aria-label': 'search' }}/>
	</>)
}

// Render different variants, right now just outlined and contained
const render = ({variant, ...props}) => {
	if (variant === "outlined") {
		return(
		<SearchOutlined onClick={(e) => e.stopPropagation()}>
			<SearchInnards {...props}/>
		</SearchOutlined>
		)
	} else if (props.variant === "contained") {
		return(
		<Search onClick={(e) => e.stopPropagation()}>
			<SearchInnards {...props}/>
		</Search>
		)
	} else {
		return(
		<Search onClick={(e) => e.stopPropagation()}>
			<SearchInnards {...props}/>
		</Search>
		)
	}
}

export default function SearchBar(props) {
	return (
		render(props)
	);
}