import React from "react";
import SearchAppBar from "./AppBar/SearchAppBar";

export default function Header (props) {

  // Pretty redundant

  return (
      <SearchAppBar/>
  );

}
