import React, { useState } from "react";
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Grid from "@mui/material/Grid";
import NavLink from "./NavLink";
import SearchBar from "../../components/Inputs/SearchBar";
import { Link, Form, useNavigate, useSubmit } from "react-router-dom";
import { IconButton, Menu, MenuItem, Typography } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';

const Bar = styled(AppBar)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    // width: 'auto',
  },
}));

const Logo = styled(Box)(({ theme }) => ({
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.black, 0.25),
  },
  height: 80,
  [theme.breakpoints.up('md')]: {
    height: 120,
  },
}));

export default function SearchAppBar() {
  // React router navigation
  const navigate = useNavigate();
  // Menu anchor for mobile menu
  const [anchorElUser, setAnchorElUser] = useState(null)
  // Search string
  const [search, setSearch] = useState("")
  // Mobile menu handlers
  const handleOpenNavMenu = (event) => {
    anchorElUser ? setAnchorElUser(null) : setAnchorElUser(event.currentTarget)
  }
  const handleCloseNavMenu = () => {
    setAnchorElUser(null)
  }
  // Search submission handler which navigates to explore with the search and closes the menu if open
  const handleSubmit = (e) => {
    navigate('/explore/'+search);
    handleCloseNavMenu()
  }

  return (
    <>
    <Box sx={{ flexGrow: 1 }}>
      <Bar position="static">
        <Toolbar sx={{alignItems:"end"}}>
          <Box>
          <Link to="/">
            <Logo
            component="img"
            alt="Logo"
            src="/ELC.png"
            />
          </Link>
          
          </Box>
          <Grid container
            color="primary.light"
            textAlign="center"
            justifyContent="end"
            sx={{display: { xs: 'none', md: 'flex' } }}
          >
            <NavLink href="/">
              Home
            </NavLink>
            <NavLink href="/explore/">
              Explore
            </NavLink>
            <NavLink href="/about/">
              About
            </NavLink>
            <Box alignSelf="center" sx={{width:275, marginLeft:1}}>
              <Form method="post">
              <SearchBar placeholder="Search..." value={search} onChange={(e) => setSearch(e.target.value)} onSubmit={handleSubmit} />
              </Form>
            </Box>
          </Grid>
        </Toolbar>
      </Bar>
    </Box>
      {/* Mobile menu below */}
      <Box sx={{pointerEvents:'none', display: { xs: 'flex', md: 'none' }, color:'primary.light', zIndex:'9999', position:'fixed', top:0, width:'100%', minHeight:'100vh', minHeight:'-webkit-fill-available'}} >
          <IconButton
            size="large"
            edge="end"
            color="inherit"
            aria-label="open drawer"
            sx={{ pointerEvents:'all', display: { xs: 'flex', md: 'none' }, zIndex:'9999', mr: 2, position:'absolute', top:'15px', right:'0px', backgroundColor:'#4f7170', ['&:hover']: {backgroundColor:"rgba(42, 64, 64, 1.0)"} }}
            onClick={handleOpenNavMenu}
          >
            <MenuIcon />
          </IconButton>
          </Box>
          <Menu
              disableScrollLock
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseNavMenu}
              onClick={handleCloseNavMenu}
            >
              <NavLink href="/">
                Home
              </NavLink>
              <NavLink href="/explore/">
                Explore
              </NavLink>
              <NavLink href="/about/">
                About
              </NavLink>
              <Box alignSelf="center" sx={{width:275, marginLeft:1}}>
                <Form method="post">
                <SearchBar interactiveIcon placeholder="Search..." value={search} onChange={(e) => setSearch(e.target.value)} onSubmit={handleSubmit} />
                </Form>
              </Box>
            </Menu>
    </>
  );

}
