import { Card, CardActionArea, CardContent, CardMedia, Typography, useTheme } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { useSetModel } from "../globals/ModelContext";
import { API_DIR, API_HOST, API_SCHEME } from "..";
import slugify from "slugify";

export default function ModelCard({data}) {
  // const { title, slug, sceneDir, thumbDir } = data
  const setModel = useSetModel()
  const theme = useTheme()
  const defaultImage = `/voyager-scenes/voyager-scene/scene-image-low.jpg`
  const defaultFilter = 'blur(1px) brightness(0.9) grayscale(1)'

  // const src = sceneDir ? `${API_SCHEME}${API_HOST}/component/${API_DIR}/${sceneDir}/scene-image-low.jpg` : `${API_SCHEME}${API_HOST}/component/${API_DIR}/${thumbDir}`
  const { id, url, title, type } = data

  const sluggedTitle = slugify(title, '_')

  const root = url ? url : 'https://3d-api.cjmoyna.com/content/document/' + id

  const image_src = root + "/"+ sluggedTitle +"-image-low.jpg"
  
  const viewModel = () => {
    setModel(data)
  }
  
  return (
    <Card sx={{ margin:1, [theme.breakpoints.up('sm')]: {height:'94%'} }}>
        <CardActionArea sx={{height:'100%'}} component={Link} to={`/${(type==="3d_package")?"3d":"collection"}/${sluggedTitle}:${id}`} onClick={viewModel}>
          { // Item has a thumbnail
            (image_src) && 
            <CardMedia
                  component='img'
                  image={image_src}
                  sx={{filter: "unset"}}
                  alt={title+' thumbnail'}
                  onError={(e) => {e.target.src = defaultImage;}}
            /> }
          { // Item has no thumbnail, use default
            (!image_src) && 
            <CardMedia
                component='img'
                image={defaultImage}
                alt={title+' thumbnail'}
                sx={{
                  filter: defaultFilter,
                }}
            /> }
          <CardContent sx={{padding:{xs:"6px",md:"8px",rg:"10px",lg:"12px"}}} >
            <Typography variant="h5" textAlign="center" sx={{
              fontSize:{xs:"0.8rem",sm:"0.9rem",md:"1.025rem",lg:"1.125rem"},
              
              }}>
              {title}
            </Typography>
          </CardContent>
        </CardActionArea>
    </Card>
  )
}
