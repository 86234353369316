import React from "react";
import { Box, Divider, Typography } from "@mui/material";

// Called on page load
export async function loader( { params } ) {
  let query = params.query;
  query = (typeof query === 'undefined' || query === 'explore') ? '' : query
  return query;
}


export default function About(props) {
  return (
    <div className="App container">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xs-12 col-md-8 offset-md-2">
          <Typography paddingTop={2} variant="h2">About</Typography>
          <Divider/>
            <div className="about">
              <Box paddingX={{sm:2}} paddingBottom={6}>
                
                <Typography paddingY={{sm:2}} pt={2} variant="body1" lineHeight={1.9}>
                <span style={{ paddingLeft:20, fontSize:'2em', lineHeight:"1"}}>T</span>
                he Earthmoving Legacy Center Digital Collection strives to showcase the spectacular
                machines and stories we have on display at our physical location,
                in Elkader, Iowa, on a global scale. This has been made possible for us to accomplish thanks to the
                use of open source technologies such as the Smithsonian's Voyager Explorer and Voyager Story.
                With the help of these advanced digitization suites and cutting edge 3D scanning technology,
                we have been able to produce interactive scenes for the machines in our collection at a
                high level of speed and consistency.
                </Typography>
                
                <Typography paddingY={{sm:2}} pt={2} variant="body1" lineHeight={1.9}>
                <span style={{ paddingLeft:20, fontSize:'2em', lineHeight:"1"}}>O</span>
                ur physical museum at the <a href="https://www.earthmovinglegacy.com/" target="blank">Earthmoving Legacy Center</a> is a 38,000 square foot facility which showcases the rich history of earthmoving. 
                The collection spans from the 1880's to 1940, focusing primarily on the evolution of the Caterpillar Company. 
                It came about as a way for John Moyna to share his large collection with everyone, whether they’re an avid tractor fan or a class of curious students. 
                In creating the Earthmoving Legacy Center, we have had the opportunity to allow other collectors to loan their pieces as well, to help showcase early track-type tractors from Caterpillar, Holt Manufacturing, and C.L. Best Manufacturing. 
                The collection features approximately 50 early pieces, ranging from horse-drawn earthmoving equipment up to the motor driven tractors of the 1940’s. 
                We plan on digitizing a large portion of this collection, with regular releases of more models and stories coming in the future. 
                </Typography>

              </Box>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
