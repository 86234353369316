import { Box, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import ModelPanel from "./ModelPanel";

export default function HomeTabs(props) {
  const [tab, setTab] = useState(0)

  // Queries 0-2 match tabs 0-2
  const queries = [
    // Featured
      { filter: {q:''}, options: { limit: 24, skip: 0, sort: { title: 'asc' }} },
    // Recent Additions
      { filter: {q:''}, options: { limit: 24, skip: 0, sort: { timestamp: 'desc' }} },
    // Popular (sort by views)
      { filter: {q:''}, options: { limit: 24, skip: 0, sort: { views: 'desc', timestamp: 'desc' }} },
    // Collections (sort by id)
      { filter: {q:''}, options: { limit: 24, skip: 0, sort: { title: 'asc' }}, type: "collections" },
  ]

  const handleChange = (e, value)=> {
    setTab(value)
  }

  return (
    <Box paddingTop={3} paddingBottom={10}>
      <Box display="flex" >
        <Tabs value={tab} onChange={handleChange} sx={{margin:"auto"}} {...props} >
          <Tab label="Featured" value={0} />
          <Tab label="Recent Additions" value={1} />
          {/* <Tab label="Popular" value={2} /> */}
          {/* <Tab label="Collections" value={3} /> */}
        </Tabs>
      </Box>
    {/* Render the model panel with the relevant filter and options associated with the current tab */}
    <ModelPanel type={queries[tab].type} filter={queries[tab].filter} options={queries[tab].options} />
    </Box>
  );
}
