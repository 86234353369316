import React from "react";
import ModelCard from "./ModelCard";
import { CircularProgress, Grid } from "@mui/material";

export default function ModelList({models}) {
  // If we have not recieved models yet display a loader
  if (models === null) return <CircularProgress size={100} sx={{margin:"auto"}} />
  // Or, if the models are empty display nothing
  else if (!models || models?.length < 1) return <></>
  // Otherwise, map the models to grid items which change their sizing on theme breakpoints based on that ugly list of letters and numbers down there
  return (
    models.map((model_data, i) => (
    <Grid item key={i} xs={6} lt={6} sm={6} rg={4} md={3} lg={2} ><ModelCard data={model_data} /></Grid>
    ))
  );
}
