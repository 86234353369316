import React from "react";
import { CircularProgress, Grid, Typography } from "@mui/material";

export default function InformationItem({entry}) {
  // If we haven't recieved information yet, display a loader
  if (entry === null) return <CircularProgress size={20} sx={{margin:"auto"}} />
  // Or if the information entry is empty just return empty
  else if (!entry || entry?.keys?.length < 1) return <></>
  // Otherwise display the entry key and value, respecting line breaks in the text with whiteSpace:'break-spaces'
  return (
    <Grid item px={{xs:1, lg:2}} sx={{whiteSpace: "break-spaces", color:"#2b4c46"}}>
      <Typography variant="h6">
        {entry.label}
      </Typography>
      <Typography variant="body1" paragraph >
        {entry.content}
      </Typography>
    </Grid>
  );
}
