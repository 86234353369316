import { Box } from "@mui/material";
import React from "react";
import { API_DIR, API_HOST, API_SCHEME } from "../..";

export default function Voyager({packageID, uiMode = "title|menu|help", ...props}) {
  
  // If we were not passed a scene, or haven't gotten it yet, load a blank explorer with no controls
  uiMode = packageID ? uiMode : "none&controls=false"
  
  const apiURL = `${API_SCHEME}${API_HOST}/explore/${packageID}?uiMode=${uiMode}`

  return (
    <Box sx={{width:"100%", height:"100%"}}>
      <iframe title="voyager-explorer" id="voyager-explorer" src={apiURL} sandbox="allow-scripts allow-same-origin allow-popups allow-scripts allow-downloads allow-presentation" allow="xr-spatial-tracking; fullscreen" style={{display:'block', position:'relative', height:"100%", width:"100%" }} {...props}/>
    </Box>
  );
}
