import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button"
import SearchBar from "../components/Inputs/SearchBar";


import { useLoaderData, useNavigate } from "react-router-dom";
import ModelPanel from "../components/ModelPanel";
import { Box, FormControlLabel, FormGroup, Grid, Switch, Typography } from "@mui/material";
import Dropdown from "../components/Inputs/Dropdown";

// Called on page load and provides the url query parameters
export async function loader( { params } ) {
  window.scrollTo(0,0)
  let query = params.query;
  query = (typeof query === 'undefined' || query === 'explore') ? '' : query
  return query;
}


export default function Explore(props) {
  const navigate = useNavigate()

  // Calls loader on page load
  const urlQuery = useLoaderData()

  // Sets starting query to url or empty, will hold the search query
  const [query, setQuery] = useState(urlQuery)

  const [filter, setFilter] = useState({q: query})

  const [order, setOrder] = useState('asc')

  const [options, setOptions] = useState({limit: 12, sort: { title: order }})

	const [lastEventCanceler, setLastEventCanceler] = useState(() => () => {})

  // Setting the filter whenever the url query is updated
  useEffect(() => {
    setQuery(urlQuery)
    setFilter({...filter, q: urlQuery})
  }, [urlQuery])

  // Handler passed to the search bar to set the query dynamically and only update the filter once the user stops typing
  // so that we are not constantly refetching from the database while typing
  const handleChange = (e) => {
    // cancels the last request if it has not been sent yet
    lastEventCanceler()
    // setting the query so that the searchbar updates 
    const newQuery = e.target.value
    setQuery(newQuery)
    // starting the delayed filter set which will trigger an api request after 400ms if not cancelled
    const delayDebounceFn = setTimeout(() => setFilter({...filter, q: newQuery}), 400)
    // sets the next key press's canceler to cancel this request if the user continues typing
    setLastEventCanceler(() => () => clearTimeout(delayDebounceFn))
  }

  // Handler passed to the search bar to produce a url on enter key press or on clicking the search icon
  const handleSubmit = (e) => {
    navigate('/explore/'+query);
  }

  const handleSort = (e) => {
    setOrder((e.target.value==="title")? 'asc': 'desc')
    setOptions({...options, sort:{[e.target.value]:(e.target.value==="title")? 'asc': 'desc'}})
  }

  return (
    <div className="App container">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xs-12 col-md-8 offset-md-2">
            <Grid container flexDirection="row" alignContent="space-between">
              <Typography paddingTop={2} variant="h2">Search...</Typography>
            </Grid>
            <div className="explore">
              <SearchBar id="explore-search" value={query} onSubmit={handleSubmit} onChange={handleChange} size="large" placeholder="" variant="outlined" interactiveIcon/>
            </div>
              <Grid item container flexDirection="row" flex={1}>
                <Button size="large" sx={{pointerEvents:"none", paddingRight:0}}>Sort By:</Button>
                <Dropdown onItemClick={handleSort} />
              </Grid>
          </div>
        </div>
      </div>
    <Box paddingTop={3} paddingBottom={8}>
      <ModelPanel paginated filter={filter} options={options} />
    </Box>
    </div>
  );
}
