import React from "react";
import { CircularProgress, Grid } from "@mui/material";
import InformationItem from "./InformationItem";
// import * as data from "../data.json";

export default function InformationList({list}) {
  // Skip if the list is empty
  if (!list || list.length < 1) return
  // Otherwise map the information entries to items to be displayed

  return (
    list.map((entry, i) => (
      <Grid container key={i}>
        <InformationItem entry={entry} key={i} />
      </Grid>
    ))
  );
}
