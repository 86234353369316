import { Grid, Pagination, Paper, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import ModelList from "./ModelList";
import { getModelsAction } from "../actions/getModels";
import { getCollectionsAction } from "../actions/getCollections";

export const ModelPagination = ({count, page, onChange}) => {
  return (
    <Pagination sx={{ paddingY:"10px", display:"flex", justifyContent:"center"}} count={count} page={page} onChange={onChange} />
  )
}

export default function ModelPanel({filter = {q:''}, options = {limit: 20, sort: { id: 'asc' }}, paginated = false, type = "models", ...props}) {
  const theme = useTheme()
  // Will hold the models fetched from the server
  const [items, setItems] = useState(null)
  // The current page we are on
  const [page, setPage] = useState(1)
  // The number of pages this query resulted in
  const [count, setCount] = useState(1)

  // If the filter or options have been changed, or we've changed page, refetch the models
  useEffect(() => {
    options.skip = ((page-1) * options.limit)

    let query = `` + 
      `q=${'*'+(filter.q??'')+'*'}` +
      `&start=${options.skip}` +
      `&rows=${options.limit}` +
      `&sort=${JSON.stringify(options.sort)}` +
      `${type === 'models' ? '&fqs=["type:3d_package"]' : 'fqs=["type:edanmdm"]'}`

    if (type==="models") getModelsAction(query)
      .then(response => {
      setCount(Math.ceil(response.total / options.limit))
      setItems(response.data)
    })
    else getCollectionsAction(query)
    .then(response => {
      setCount(Math.ceil(response.total / options.limit))
      setItems(response.data)
    })
  }, [filter, options, type, page]);

  const handlePageChange = (event, value) => {
    setPage(value)
  }

  // If we are loading
  const isLoading = !(items)
  // Display the model list so the user sees a loader circle, otherwise, if items is empty show 'No results!'
  const hasResults = isLoading? true : items.length !== 0
  return (
    // On small screen sizes and less, hide this background element for visual clarity and still show the children
    <Paper elevation={20} sx={{ [theme.breakpoints.down('sm')]: {visibility:'hidden', ['>*']:{visibility:'visible'} } }} {...props} >
      {/* Only show page navigation if the pagination prop is true */}
      {paginated && <ModelPagination count={count} page={page} onChange={handlePageChange} />}
      <Grid p={2} container flexDirection="row">
        {hasResults && <ModelList models={items}/> }
        {!hasResults && <Typography variant="h2" margin="auto" align="center" >No results.</Typography>}
      </Grid>
      {paginated && <ModelPagination count={count} page={page} onChange={handlePageChange} />}
    </Paper>
  )
}
