import React, { useContext, useState } from "react";

const ModelContext = React.createContext();
const SetModelContext = React.createContext();

export function useModel() {
  return useContext(ModelContext);
}

export function useSetModel() {
  return useContext(SetModelContext);
}

export function ModelProvider({ children }) {
  const [model, setModel] = useState({});

  return (
    <ModelContext.Provider value={model}>
      <SetModelContext.Provider value={setModel}>
        {children}
      </SetModelContext.Provider>
    </ModelContext.Provider>
  );
}
