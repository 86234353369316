import { getModels as getModelsAPI } from "../api";
import { stringify } from 'query-string';

export const getModelsBySlugAction = async (slug) => {
  // Stringifying objects into JSON so they can be passed as query
  const filter = JSON.stringify({slug, published: true})
  const options = JSON.stringify({})
  // Same here with whole query-string
  const query = stringify({filter, options})
  // Send the request
  const response = await getModelsAPI(query);
  // Return the model
  return response.data.data;
};
