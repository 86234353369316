import React from "react";
import Header from "./Header";
import { Outlet } from "react-router-dom";
import { ThemeProvider, createTheme, responsiveFontSizes } from "@mui/material";
import Footer from "./Footer";

const customBreakpointValues = {
  values: {
    xs: 0,
    lt: 400,
    sm: 600,
    rg: 768,
    md: 900,
    lg: 1200,
    xl: 1536,
  },
}

const defaultTheme = createTheme({breakpoints: {...customBreakpointValues}});

// Theme details for global theme context
var theme = createTheme({
  palette: {
      mode: "light",
      primary: {
        main: "#4f7170",
        light: "#e5eaea",
        warn: "#c09725",
      },
      secondary: {
        main: "#d90429",
      },
      background: {
        main: "#edf2f4",
      },
      gray: {
        main: "#8d99ae",
      },
    },
  breakpoints: {
    ...customBreakpointValues,
  },
});

theme = responsiveFontSizes(theme, {factor:4})

// Exists as the parent component for all non admin pages so they all share the same header and footer

export default function Layout({children}) {
  return (
    <ThemeProvider theme={theme}>
      <div className="App layout" style={{height:"100%", display:"flex", flexDirection:"column"}}>
        <div style={{flex: "1 0 auto"}}>
          <Header/>
          <Outlet/>
          { children }
        </div>
        <Footer sx={{flexShrink:0}}/>
      </div>
    </ThemeProvider>
  );
}
