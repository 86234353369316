import React, { useEffect } from "react";
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { useLoaderData } from "react-router-dom";
import { Box, Divider, Grid, Icon, Typography } from "@mui/material";
import { getModelsBySlugAction } from "../actions/getModelsBySlug";
import { API_DIR, API_HOST, API_SCHEME } from "../";

// Called on page load and provides the slug to the fetch action which returns to the component
export async function loader({ request }) {
  const path = new URL(request.url).pathname
  const slug = path.split('/').pop()
  
  window.scrollTo(0,0)

  // const cards = await getModelsBySlugAction(slug).catch(e => null)
  // {content, sx, tour, scene, position, children, hidden=false, fullWidth=false, ...props}
  const cards = [
    {"tour":"0","scene":"0","position":"left","color":"#fff","backgroundColor":"rgba(22,19,11,0.75)","border":"1px solid #665721","content": "<h2>Descriptive Cards</h2><p>Each descriptive card can provide information to contextualize snapshots along each step of the tour.</p><p>By providing intriguing visuals along with additional reading you invite viewers to be curious and receptive towards your content.</p>"},
    {"tour": "0","scene": "1","position": "right","color": "#fff","backgroundColor": "rgba(22,19,11,0.75)","border": "1px solid #665721","content": "<h2>Left, Right, and Center!</h2><p>Switch up the presentation by aligning cards to both sides of the screen as well as utilizing centralized cards to focus your viewers on important reading or images.</p>"},
    {"position": "center","content": "<h2>Draw Attention</h2><img src='https://static.wixstatic.com/media/b61e2a_1c017d93fb0840d1a055779040509288~mv2.jpg/v1/fit/w_1600,h_821,q_90/b61e2a_1c017d93fb0840d1a055779040509288~mv2.webp' width='100%'/><br/><br/><p>Centralized spanning cards can be used to strike more of an impact. Recommended use is after a side card of the same tour step so that movement and visuals are not obscured while changing tour steps.</p>"},
    {"tour": "0","scene": "2","position": "left","color": "#fff","backgroundColor": "rgba(22,19,11,0.75)","border": "1px solid #665721","content": ""},
    {"tour": "0","scene": "3","position": "right","color": "#fff","backgroundColor": "rgba(22,19,11,0.75)","border": "1px solid #665721","content": ""},
    {"tour": "0","scene": "4","position": "left","color": "#fff","backgroundColor": "rgba(22,19,11,0.75)","border": "1px solid #665721","content": ""},
    {"tour": "0","scene": "5","position": "right","color": "#fff","backgroundColor": "rgba(22,19,11,0.75)","border": "1px solid #665721","content": ""},
    {"tour": "0","scene": "6","position": "left","color": "#fff","backgroundColor": "rgba(22,19,11,0.75)","border": "1px solid #665721","content": ""},
  ]
  
  return (cards ? cards : [])
}

const postMessageWithPortListener = (window, data, intendedTarget, callback) => {
  const channel = new MessageChannel();

  channel.port1.addEventListener(
    "message",
    callback
  )
  channel.port1.start()

  window.postMessage(data, intendedTarget, [channel.port2])
}

export default function Story(props) {
  const guideCards = useLoaderData();
  // const { pathname } = useLocation()
  // const directory = pathname.replace(/^\/showcase+/, '')
  const directory = "/caterpillar-25-gray/"

  // // Listener for cross domain messages through iframes
  // window.addEventListener(
  //   "message",
  //   (event) => {
  //     // Get the data passed by the sender, it should be of the form {action:String,params:[String]}
  //     const { data={} } = event
  //     // Deconstruct the message to get the requested action and parameters 
  //     const { action, params=[] } = data
  //     // Stop if no action was passed in the message 
  //     if (!action) return;
  //     // Otherwise, run the action on the explorer with the supplied parameters
  //     const response = document.getElementsByTagName("voyager-explorer")[0][action](...params);
  //     // Then send the result back to the sender as a response message through the first supplied port from the requester
  //     event.ports[0].postMessage(response)
  //   },
  //   false,
  // );

  useEffect(() => {
    // Load the explorer script
    if (!document.getElementById("voyager-explorer-script")) {
      const script = document.createElement("script");
      script.src = "https://3d-api.si.edu/resources/js/voyager-explorer.min.js";
      script.async = true;
      script.id = "voyager-explorer-script"
      document.body.appendChild(script);
    }

    // Callback provided to the observer to change tour step on tour guide observation
    const callback = (entries, observer) => {
      entries.forEach(entry => {
        const { target } = entry
        // When seen
        if (entry.isIntersecting > 0) {
          // If entry is a tour guide
          if (target.classList.contains("tour-guide")) {
            const tour = target.getAttribute("data-tour")
            const scene = target.getAttribute("data-scene")
            // Set the tour step to that which is detailed in its data fields (after a short delay)
            if (tour && scene) setTimeout(() => {
              document.getElementsByTagName("voyager-explorer")[0].setTourStep(tour, scene)
            }, 250);
          }
          // And enable its visibility
          target.classList.add("show");
        } // Otherwise
        else { // Disable visibility
          target.classList.remove("show");
        }
      });
    };
    
    // Options for the observer
    let options = {
      rootMargin: "0px",
      threshold: [0.1,0.5]
    };

    // Declaring the observer
    const observer = new IntersectionObserver(callback, options);

    // Setup dynamic tour stepping according to tour guides
    const sections = document.querySelectorAll(".tour-guide")

    // And assign the observer
    sections.forEach(section => {
      observer.observe(section);
    });
   },[])

  return (    
    <Box>
      <div style={{top:0, left:0, right:0, width:"100%", height:"100%", zIndex:"-10", position:"fixed"}} >
        <voyager-explorer root={`${API_SCHEME}${API_HOST}/component/${API_DIR}${directory}`} prompt={false} uiMode={"none"} />
      </div>
      <GuideTitle>
        <Typography variant="h2" sx={{margin:"none"}} paddingY={2} >Placeholder</Typography>
      </GuideTitle>
      {guideCards.map(({content, ...card}, index) => {
        return (
        <GuideCard key={index} content={content} {...card} />
        )
      })}
    </Box>
  );
}

const GuideTitle = ({sx, children, ...props}) => {
  return (
    <Box sx={{marginBottom:"100vh", backgroundColor:"#111", color:"#fff", textAlign:"center", ...sx}} className="tour-title" {...props} >
      <Box maxWidth="675px" margin="auto" >
        {children}
        {/* <KeyboardDoubleArrowDownIcon sx={{fontSize:"60px", marginY:2}}/>  */}
      </Box>
    </Box>
  )
}

const GuideCard = ({content, sx, tour, scene, position, children, hidden=false, fullWidth=false, ...props}) => {
  const positions = {
    "left": {
      width: "35%",
      minWidth: "300px",
      maxWidth: "400px",
      margin: "0 0 100vh 2%",
    },
    "right": {
      width: "35%",
      minWidth: "300px",
      maxWidth: "400px",
      margin: "auto",
      marginRight: "2%",
      marginBottom: "100vh"
    },
    "center": {
      textAlign: "center",
      margin: "0 1.2rem 100vh 1.2rem",
    }
  }
  
  const fadeInStyle = {
    "&:not(.show)": {
      opacity:0
    },
    transition: "opacity 0.15s linear"
  }

  return !hidden ? (
    <Box 
        className="tour-guide" 
        data-tour={tour} 
        data-scene={scene} 
        sx={{
          ...positions[position], 
          ...fadeInStyle, 
          backgroundColor:"rgba(22,19,11,0.75)", 
          color:"#fff", 
          border:"1px solid #665721",
          padding:"1.2em",
          ...sx
          }}
        {...props}
      >
        {(position === "center" && !fullWidth) ? <Box sx={{maxWidth:"675px", margin:"auto"}} dangerouslySetInnerHTML={{__html: content}} /> : <Box dangerouslySetInnerHTML={{__html: content}} />}
    </Box>
  ) : (
    <Box 
        className="tour-guide" 
        data-tour={tour} 
        data-scene={scene} 
        sx={{
          marginBottom: "100vh",
          ...sx
          }}
        {...props}
      >
        <br/>
      </Box>
  )
}
