import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App, {loader as modelLoader} from './pages/App';
import * as serviceWorker from './serviceWorker';

import './index.scss';
import Home, {loader as heroLoader} from './pages/Home';
import Error from './pages/Error';
import Explore, {loader as exploreLoader} from './pages/Explore';
import Layout from './layout/Layout';
import { ModelProvider } from './globals/ModelContext';
import About from './pages/About';
import Showcase, {loader as showcaseLoader}  from './pages/Showcase';
import Collection, {loader as collectionLoader} from './pages/Collection';

// Router functionality for global react router context, this sets the pages according to the url
const router = createBrowserRouter([
    // User Pages
    {
      // Parent Layout (header and footer) + catch all Error Page
      element: <Layout/>,
      errorElement: <Error/>,
      // Nested pages
      children: [
        {
          path: "/",
          element: <Home/>,
          loader: heroLoader,
        },
        {
            path: "/3d/:slug?",
            element: <App/>,
            loader: modelLoader,
        },
        {
            path: "/collection/:slug?",
            element: <Collection/>,
            loader: collectionLoader,
        },
        {
            path: "/explore/:query?",
            element: <Explore/>,
            loader: exploreLoader,
        },
        {
            path: "/showcase/:query?",
            element: <Showcase/>,
            loader: showcaseLoader,
        },
        {
            path: "/about",
            element: <About/>,
        },
      ],
    },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
      <ModelProvider>
        {/* React router provider */}
        <RouterProvider router={router} />
      </ModelProvider>
    </React.StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

export const API_SCHEME = "https://"
export const API_HOST = "3d-api.cjmoyna.com"
export const API_DIR = "voyager/elc/public"