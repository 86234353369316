import React, { useEffect, useState } from "react";
import { useRouteError } from "react-router-dom";
import Layout from "../layout/Layout";

export default function Error(props) {
  const error = useRouteError();
  console.warn(error);

  // Default error page. Mostly for 404's and the like.

  return (
  <Layout>
    <div className="App container">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xs-12 col-md-8 offset-md-2" style={{textAlign:"center"}}>
            <br/>
            <h1><i>{error.statusText || error.message}</i></h1>
            <div className="todo-app">
              <p>Sorry, an error has occurred.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
  );
}
