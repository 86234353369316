import { Button, Menu, MenuItem } from "@mui/material";
import { useState } from "react";


export default function Dropdown({onItemClick}) {
  const [label, setLabel] = useState("title");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleItemClick = (item) => (event) => {
    setLabel(event.target.innerText)
    onItemClick({...event, target:{...event.target, value:item}})
    handleClose()
  }
  return (
    <div>
      <Button
        size="large"
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {label}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleItemClick("title")}>Title</MenuItem>
        <MenuItem onClick={handleItemClick("timestamp")}>Publish Date</MenuItem>
      </Menu>
    </div>
  )
}