import React from "react";
import Voyager from "../components/Voyager/Voyager";
import Button from "@mui/material/Button"
import { useLoaderData, useNavigate } from "react-router-dom";
import { Box, Divider, Grid, Typography } from "@mui/material";
import ModelPanel from "../components/ModelPanel"
import { getModelsAction } from "../actions/getModels";
import InformationBlock from "../components/Information/InformationBlock";

// Called on page load and provides the record
export async function loader({ request }) {
  const path = new URL(request.url).pathname

  const id = path.split(':').pop()
  
  window.scrollTo(0,0)

  const records = await getModelsAction(`fqs=["type:3d_package","id:${id}"]&fl=title,linkedId,linkedContent:[json]`).catch(e => null)

  const record = records?.data?.length > 0 ? records?.data[0] : null
  
  if      (!record || !id)                  return {record:null, id:null}
  else if (record.linkedContent?.freetext)  return {record, id}
  else if (record.linkedId) {
    const linkedRecords = await getModelsAction(`fqs=["type:edanmdm","id:${record.linkedId}"]&fl=content:[json]`).catch(e => null)
    const linkedRecord = linkedRecords?.data?.length > 0 ? linkedRecords?.data[0] : null

    record.linkedContent = linkedRecord?.content
  }

  return ({ record, id })
}

const toggleVoyager = (e) => {
  const hidden = document.getElementById("voyager-explorer").hidden
  document.getElementById("voyager-explorer").hidden = !hidden
  document.getElementById("gallery").style.maxWidth = (document.getElementById("gallery").style.maxWidth === '0px') ? 'initial' : '0px'
  e.target.innerText = hidden ? "Hide Showcase" : "View Showcase"
}

export default function App(props) {
  // Global model context
  const { record, id } = useLoaderData()
  const navigate = useNavigate();

  if (!record) {
    return (
    <div className="App container">
      <div className="container-fluid">
        <div className="col-xs-12 offset-md-1">
          <Typography variant="h2" paddingTop={2}>We couldn't find that model in our database.</Typography>
          <Typography variant="h5" paddingTop={2} fontWeight={200} >You can try exploring some of these featured items or <a href="" onClick={e => {e.preventDefault(); return navigate(-1)}}>return to your last page</a>.</Typography>
        </div>
        <br/>
        <ModelPanel filter={{featured: true}} options={{ limit: 12, skip: 0, sort: { timestamp: 1 }}}/>
      </div>
    </div>
    )
  }
  
  const { title, linkedContent } = record

  const {descriptiveNonRepeating, freetext} = linkedContent
  console.log(descriptiveNonRepeating, freetext)
  return (
    <>
    <Grid container columns={{ xs: 4, md: 12 }} >
    <Grid item xs={7.5} sx={{  paddingX:0, }} >
    <div className="App container">
      <div className="container-fluid">
          <div className="col-xs-12 offset-md-1">
          <Typography variant="h2" paddingTop={2}>{title}</Typography>
        </div>
              
      </div>
    </div>
      <Divider/>
        <Box sx={{display:"inline-block", paddingLeft:{xs:0.5, md:1}}}/>
        <Button sx={{minWidth:"40px"}} onClick={e => navigate(-1)}>
          {"<"}
        </Button>
        {/* <Button onClick={grabFullscreen} >
          Go Fullscreen
        </Button> */}
        <Button onClick={toggleVoyager}>
          Hide Showcase
        </Button>
      <Divider/>
        <Grid id="gallery" item sx={{ padding:{xs:0, md:2} }}>
          {/* This box controls the size of the voyager explorer and keeps it in reasonable aspect ratios throughout theme breakpoints */}
          <Box id="voyager-box" sx={{ aspectRatio:{xs:"1/1", sm:"14/9", md:"1/1", lg:"15/9"}, width:"100%", height:"auto"}} >
            <Voyager packageID={id} />
          {/* <Typography variant="h6" width="50%" display="inline-block" >{descriptiveNonRepeating?.title?.label}: {descriptiveNonRepeating?.title?.content}</Typography>
          <Typography variant="h6" width="50%" align="right" display="inline-block" >Processed by {descriptiveNonRepeating?.data_source}</Typography> */}
          </Box>
        </Grid>
    </Grid>
        <Grid item xs={4.5} sx={{ borderLeft:{md:'1px solid lightgray'}, backgroundColor:{xs:'lightgray', md:'inherit'} }}>
          <Box sx={{ overflowY:"auto", height:"100%", position:"relative", paddingY:{xs:2, md: 3}, paddingLeft:{xs:2, md:2} }} >
          <Typography variant="h2">
            Information
          </Typography>
          <Divider/>
          <Box p={{xs:0.5, lg:2}} xs={1} sx={{whiteSpace: "break-spaces", color:"#2b4c46", position:{xs:"inherit", md:"absolute"}}}>
            <InformationBlock freetext={freetext} descriptiveNonRepeating={descriptiveNonRepeating} />
          </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
