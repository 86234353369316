import React from "react";
import { styled, alpha } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { Link } from "react-router-dom";

const StyledLink = styled(Typography)(({ theme }) => ({

  '&:hover': {
    backgroundColor: alpha(theme.palette.common.black, 0.25),
    cursor: "pointer",
  },
}));

export default function NavLink({children, href}) {
  return (
    <Link to={href} style={{color: "inherit", textDecoration: "inherit"}}>
    {/* // <a href={href} style={{color: "inherit", textDecoration: "inherit"}}> */}
      <StyledLink p={1} variant="h4" noWrap>
        {children}
      </StyledLink>
    {/* // </a> */}
    </Link>
  );

}
