import { getModels as getModelsAPI } from "../api";
import { stringify } from 'query-string';

export const getModelsAction = async (query) => {
  // // Stringifying objects into JSON so they can be passed as query
  // filter = JSON.stringify({...filter, isHeroScene: (filter.isHeroScene?filter.isHeroScene:false), published: true})
  // options = JSON.stringify(options)
  // // Same here with whole query-string
  // const query = stringify({filter, options})
  // Send the request
  // const response = await getModelsAPI(query);
  const response = await getModelsAPI(query);
  // Send back the models for the current page and how many total models pass the filter
  return {data: response.data.rows, total: response.data.rowCount};
};
