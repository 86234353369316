import axios from "axios";

export const API = axios.create({
  baseURL: 'https://3d-api.cjmoyna.com/packrat',
});

//example request
export const createModel = (model) => API.post("/admin/models", model );

export const getModel = (modelID) => API.get("/models/" + modelID);

export const getModels = (query) => API.get("/metadata/?" + query);

export const updateModel = (model) => API.put("/admin/models", model );

export const deleteModel = (modelID) => API.delete("/admin/models/" + modelID);

export const getFiles = (query) => {
  const token = localStorage.getItem('token');
  return API.get("/admin/files?" + query, {headers:{'Authorization': `Bearer ${token}`}});
}

export const uploadFiles = (fd, id) => {
  const token = localStorage.getItem('token');
  return API.post("/admin/files/"+id, fd, {headers:{'Authorization': `Bearer ${token}`, 'content-transfer-encoding': 'binary'}});
}

export const createChange = (change) => {
  const token = localStorage.getItem('token');
  return API.post("/admin/changes", change, {headers:{'Authorization': `Bearer ${token}`}});
}

export const getCollections = (query) => API.get("/collections?" + query);
