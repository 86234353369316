import { Box, Divider, Grid, Typography, styled, } from "@mui/material";
import React from "react";
import { API_HOST, API_SCHEME, API_DIR } from "../..";

export default function VoyagerHero({packageID, ...props}) {
  
  const heroURL = `${API_SCHEME}${API_HOST}/explore/${packageID}/?uiMode=none&controls=false`

  const Hero = styled(Box)(({ theme }) => ({
    minHeight:"450px",
    overflow:"auto",
    background: "rgb(208,208,208)",
    background: "linear-gradient(0deg, rgba(208,208,208,0.3) 0%, rgba(200,200,203,0.4) 35%, rgba(200,200,200,0.9) 78%)",
    [theme.breakpoints.up('md')]: {
      background:"rgba(200,200,200,.6)",
    },
  }));

  const Panel = styled(Box)(({ theme }) => ({
    minHeight:"450px",
    overflow:"auto",
    padding: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      width:"50%",
      padding: theme.spacing(3),
    },
  }));

  return (
    <Hero>
      <Grid container direction="row-reverse">
        <Panel>
            <Typography variant="h2">
            Explore the Collection
          </Typography>
          <Divider/>
          <Box p={{xs:2, sm:3}}>
            <Typography>
              Welcome to the Earthmoving Legacy Center's digital collection.
            </Typography>
            <Typography>
              Here you can find 3D scans of the historical antique tractors and machines that are featured in the Earthmoving Legacy Center's collection. <br/><br/>
              This digital exhibition contains machines ranging from the most essential mass produced tractor models, which drove the burgeoning earthmoving industry, to unique, one of a kind, oddities seen nowhere else.<br/><br/>
              Learn more about the history and legacy these machines left in their wake by exploring the articles and virtual tours included in the fully explorable 3D scenes below. <br/>
              
            </Typography>
          </Box>
        </Panel>
        <Box flexGrow={1}>
        <Box id="voyager-box" sx={{ aspectRatio:{xs:"1/1", sm:"16/9", md:"auto"}, width:"100%", height:"100%"}} >
          <iframe title="voyager-explorer" id="voyager-explorer" src={heroURL} allow="xr; xr-spatial-tracking; fullscreen" style={{display:'block', position:'relative', height:"100%", width:"100%", pointerEvents:"none", userSelect:"none", msUserSelect:"none", MozUserSelect:"none", WebkitUserSelect:"none", }} {...props}/>
          {/* <voyager-explorer uiMode="none" controls={false} dragdrop={false} root="/voyager-scenes/hero-scene/" style={{display:'block', position:'relative', height:'100%', pointerEvents:'none'}} /> */}
        </Box></Box>
      </Grid>
    </Hero>
  );
}
