import React from "react";
import Button from "@mui/material/Button"
import { useLoaderData, useNavigate, useNavigation } from "react-router-dom";
import { Box, Divider, Grid, Typography } from "@mui/material";
import ModelPanel from "../components/ModelPanel"
import { getCollectionsBySlugAction } from "../actions/getCollectionsBySlug";
import ModelCard from "../components/ModelCard";
import ModelList from "../components/ModelList";
import { getModelsAction } from "../actions/getModels";

// Called on page load and provides the slug to the fetch action which returns to the component
export async function loader({ request }) {
  const path = new URL(request.url).pathname

  const slug = path.split('/').pop()
  
  window.scrollTo(0,0)

  const collections = await getCollectionsBySlugAction(slug).catch(e => null)

  const collection = collections[0]
  
  const models = await getModelsAction({_id: {$in: collection.models}})

  collection.models = models.data

  return (collection ? collection : null)
}

const toggleVoyager = (e) => {
  const hidden = document.getElementById("voyager-box").hidden
  document.getElementById("voyager-box").hidden = !hidden
  document.getElementById("gallery").style.maxWidth = (document.getElementById("gallery").style.maxWidth === '0px') ? 'inherit' : '0px'
  e.target.innerText = hidden ? "Hide Showcase" : "View Showcase"
}

export default function Collection(props) {
  const collection = useLoaderData()
  const navigate = useNavigate();

  if (!collection) {
    return (
    <div className="App container">
      <div className="container-fluid">
        <div className="col-xs-12 offset-md-1">
          <Typography variant="h2" paddingTop={2}>We couldn't find that collection in our database.</Typography>
          <Typography variant="h5" paddingTop={2} fontWeight={200} >You can try exploring some of these featured items or <a href="" onClick={e => {e.preventDefault(); return navigate(-1)}}>return to your last page</a>.</Typography>
        </div>
        <br/>
        <ModelPanel filter={{featured: true}} options={{ limit: 12, skip: 0, sort: { timestamp: 1 }}}/>
      </div>
    </div>
    )
  }

  const { title, subtitle, body, models } = collection

  return (
    <>
    <div className="App container">
      <div className="container-fluid">
          <div className="col-xs-12">
          <Typography sx={{textAlign:"center"}} variant="h2" paddingTop={2}>{title}</Typography>
          <Typography sx={{textAlign:"center"}} variant="h5" paddingY={2}>{subtitle}</Typography>
        </div>
      </div>
    </div>
    <Box sx={{  paddingX:{sm:0, md:3, lg: 6}, }} >
      <Divider/>
        <Button sx={{minWidth:"40px"}} onClick={e => navigate(-1)}>
          {"<"}
        </Button>
        <Button onClick={toggleVoyager}>
          Hide Showcase
        </Button>
      <Divider/>
      <div className="App container">
      <div className="container-fluid">
          <div className="col-xs-12">
        <Typography component="p" sx={{ whiteSpace:"pre-wrap", padding:{xs:2, md:3}, fontSize:"1.1rem"}} variant="body" paddingTop={2}>{body}</Typography>
      <Grid container columns={{ xs: 4, md: 12 }} >
        <Grid id="gallery" item xs={12} sx={{ paddingBottom:{xs:2, md: 3},  paddingX:{xs:1, md:2}}}>
          <Box id="voyager-box" sx={{ width:"100%" }} >
            <Grid container direction="row" columnSpacing={1}>
              <ModelList models={models} />
            </Grid>
          </Box>
        </Grid>
      </Grid>
        </div>
      </div>
      </div>
    </Box>
    </>
  );
}
