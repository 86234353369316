import { getCollections as getCollectionsAPI } from "../api";
import { stringify } from 'query-string';

export const getCollectionsAction = async (filter = {}, options = {}) => {
  // Stringifying objects into JSON so they can be passed as query
  filter = JSON.stringify({...filter, published: true})
  options = JSON.stringify(options)
  // Same here with whole query-string
  const query = stringify({filter, options})
  // Send the request
  const response = await getCollectionsAPI(query);
  // Send back the collections for the current page and how many total collections pass the filter
  return {data: response.data.data, total: response.data.total};
};
